import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAppStore, faGithub, faInstagram, faLinkedin, faTwitter, faMastodon } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef } from "react";

import {ReactComponent as MailIcon} from "../resources/MailIcon1.1.svg"

type Props = {
    backgroundColor: string
    foregroundColor?: string
    shouldGradient?: boolean
}

function Footer(props: Props): JSX.Element {
    const parent = useRef<HTMLElement>(null);

    function setBackground() {
        if (parent.current === null) {
            return;
        }

        let background: string;
        if (props.shouldGradient === undefined || props.shouldGradient) {
            background = makeFadeGradient(props.backgroundColor);
        }
        else {
            background = props.backgroundColor;
        }

        parent.current.style.background = background;
        if (props.foregroundColor !== undefined) {
            parent.current.style.color = "black";
        }
    }

    function makeFadeGradient(color: string): string {
        return `
        linear-gradient(180deg, ${color} 1%,
            #413e57 100%)`;
    }

    useEffect(() => {
        setBackground()
    }, [parent]);

    const socialFontSize = 25;
    
    return (
    <>
    <footer className="pt-7 pb-9 sans-font mt-auto" style={{color: "white"}} ref={parent}>
        <div className="ml-auto mr-auto grid-cols-1 sm:grid-cols-2 max-w-6xl pl-8 pr-8" style={{display: "grid"}}>
            <div className="text-center sm:text-left opacity-80" style={{fontSize: "12pt"}}>
            &copy; 2024 Vincent Neo
            </div>
            <div className="text-center sm:text-right mt-5 sm:mt-0">
                <div className="flex flex-row items-center justify-center sm:justify-end space-x-3">
                    <a href="mailto:contact@vincentneo.com">
                        <MailIcon width={socialFontSize + 2} height={socialFontSize + 3} style={{marginBottom: 3}}/>
                        {/* <FontAwesomeIcon icon={faEnvelope} fontSize={socialFontSize} /> */}
                    </a>
                    <a href="https://twitter.com/ivincentneo">
                        <FontAwesomeIcon icon={faTwitter} fontSize={socialFontSize} />
                    </a>
                    <a href="https://mastodon.social/@vincentneo" rel="me">
                        <FontAwesomeIcon icon={faMastodon} fontSize={socialFontSize} />
                    </a>
                    <a href="https://www.instagram.com/neo.vincent/">
                        <FontAwesomeIcon icon={faInstagram} fontSize={socialFontSize} />
                    </a>
                    {/* <a href="https://www.linkedin.com/in/vincent-neo-67746818a/">
                        <FontAwesomeIcon icon={faLinkedin} fontSize={socialFontSize} />
                    </a> */}
                    <a href="https://github.com/vincentneo">
                        <FontAwesomeIcon icon={faGithub} fontSize={socialFontSize} />
                    </a>
                    <a href="https://apps.apple.com/sg/developer/vincent-neo/id1523681069">
                        <FontAwesomeIcon icon={faAppStore} fontSize={socialFontSize} />
                    </a>
                </div>
            </div>
        </div>
    </footer>
    </>
    )
}

export default Footer;