import { useEffect } from 'react';
import { DateTime } from 'luxon';
import BlogMetadataInterface from './BlogMetadataInterface';

import style from "./BlogPageContainer.module.css";
import Footer from '../../Footer';

export type Props = {
    onBackgroundChange: (newColor: string) => void
    metadata: BlogMetadataInterface
    children: JSX.Element
}

function BlogPageContainer(props: Props): JSX.Element {

    const backgroundColor = "#eeeeee";

    useEffect(() => {
        document.title = "Vincent's Blog | " + props.metadata.title;
        props.onBackgroundChange(backgroundColor)
    }, [props]);
    
    return (
        <>
        <div className='md:max-w-2xl lg:max-w-3xl xl:max-w-4xl mx-auto mb-24'>
            <div className={'flex flex-col justify-center ' + style.topBanner + ' ml-6 mr-6 md:ml-0 md:mr-0'}>
                <img src={props.metadata.headerImage} alt="article banner" className={style.topImage}/>
                <h1 className={style.title}>{props.metadata.title}</h1>
                <h2 className={style.dateLabel}>{DateTime.fromFormat(props.metadata.date, "yyyy-LL-dd HH:mm:ss ZZZ").toFormat("d LLLL y")}</h2>
            </div>
            <div className={style.contents + " pl-3 pr-3 md:p-0"}>
                {props.children}
            </div>
        </div>
        <Footer backgroundColor={backgroundColor} foregroundColor="black" shouldGradient={false} />
        </>
    )
}

export default BlogPageContainer;