import BlogMetadataInterface from "./BlogMetadataInterface";
import {MDXProps} from "mdx/types";
import { DateTime } from "luxon";

import article1Page from "./v1articles/2021-06-04-The_swiftPod.mdx";
import article2Page from "./v1articles/2021-06-01-PD4-EnvironmentApp.mdx";
import article3Page from "./v1articles/2021-03-11-PD3-Atmosphere.mdx";
import article4Page from "./v1articles/2021-02-13-PD2-AI-For-Visually-Impaired.mdx";
import article5Page from "./v1articles/2020-11-24-Transport-App.mdx";
import article6Page from "./v1articles/2020-11-05-Whats-Next-Sunlight.mdx";

const article1Metadata = require("./v1articles/2021-06-04-The_swiftPod.mdx").metadata as BlogMetadataInterface;
const article2Metadata = require("./v1articles/2021-06-01-PD4-EnvironmentApp.mdx").metadata as BlogMetadataInterface;
const article3Metadata = require("./v1articles/2021-03-11-PD3-Atmosphere.mdx").metadata as BlogMetadataInterface;
const article4Metadata = require("./v1articles/2021-02-13-PD2-AI-For-Visually-Impaired.mdx").metadata as BlogMetadataInterface;
const article5Metadata = require("./v1articles/2020-11-24-Transport-App.mdx").metadata as BlogMetadataInterface;
const article6Metadata = require("./v1articles/2020-11-05-Whats-Next-Sunlight.mdx").metadata as BlogMetadataInterface;

export class BlogContentPair {
    metadata: BlogMetadataInterface;
    content: (props: MDXProps) => JSX.Element;
    pagePath: string;

    constructor(metadata: BlogMetadataInterface, content: (props: MDXProps) => JSX.Element) {
        this.metadata = metadata;
        this.content = content;
        this.pagePath = DateTime.fromFormat(metadata.date, "yyyy-LL-dd HH:mm:ss ZZZ").toFormat("yyyy-LL-dd/") + metadata.title.toLowerCase().replace(/ /g, "-").replace(/([^A-Za-z0-9-])/g, "");
        console.log(this.pagePath)
    }
}

export default class BlogContents {
    private static shared: BlogContents;

    contents: BlogContentPair[];

    static getInstance(): BlogContents {
        if (!BlogContents.shared) {
            BlogContents.shared = new BlogContents;
        }
        return BlogContents.shared
    }

    private constructor() {
        const article1 = new BlogContentPair(article1Metadata, article1Page);
        const article2 = new BlogContentPair(article2Metadata, article2Page);
        const article3 = new BlogContentPair(article3Metadata, article3Page);
        const article4 = new BlogContentPair(article4Metadata, article4Page);
        const article5 = new BlogContentPair(article5Metadata, article5Page);
        const article6 = new BlogContentPair(article6Metadata, article6Page);
        this.contents = [article1, article2, article3, article4, article5, article6];
    }
}