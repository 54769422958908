import { useEffect, useRef, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import HomeFeature from "../structures/HomeFeature";

export type ImageProps = {
    currentFeature: HomeFeature;
}

function HomeContentImage(props: ImageProps) {
    const imageContainer = useRef<HTMLImageElement>(null);

    return (
        <div className="flex flex-1 flex-col justify-center h-full w-full">
        {/* <div className="right-contents"> */}
            <img /*width={1315} height={1208} */src={props.currentFeature.imageUrl} alt={`featured product: ${props.currentFeature.productTitle}`} className="mx-auto justify-center w-full" ref={imageContainer}/>
        </div>
        // <SwitchTransition>
        //     <CSSTransition 
        //     nodeRef={imageContainer}
        //     //in addEndListener={(done) => imageContainer.current!.addEventListener("transitionend", done, false)}
        //     key={props.currentFeature.imageUrl} 
        //     addEndListener={( done) => imageContainer.current!.addEventListener("transitionend", done, false)}
        //     classNames="fade">
        //         <div className="flex flex-1 flex-col justify-center h-full">
        //         {/* <div className="right-contents"> */}
        //             <img /*width={1315} height={1208} */src={props.currentFeature.imageUrl} alt={`featured product: ${props.currentFeature.productTitle}`} className="mx-auto justify-center w-full" ref={imageContainer}/>
        //         </div>
        //     </CSSTransition>
        // </SwitchTransition>
    )
}

export default HomeContentImage;