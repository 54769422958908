import { useEffect } from "react";
import Achievements from "./Achievements";
import Footer from "./Footer";
import HomeContent, { HomeContentProps } from "./HomeContent";
import PressMentions from "./PressMentions";

function Home(props: HomeContentProps): JSX.Element {
    useEffect(() => {
        document.title = "Vincent Neo's Website";
    }, []);
    return (
        <>
            <HomeContent onBackgroundChange={props.onBackgroundChange}/>
            {/* <AboutMe /> */}
            <Achievements />
            <PressMentions />
            <Footer backgroundColor="#837bb7" />
        </>
    )
}

export default Home;