import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

import birdLogo from "../resources/favicon.png";

import "./Bar.css";


function Bar() {

	const navBarRef = useRef<HTMLElement>(null);
	const navBarContentRef = useRef<HTMLDivElement>(null);
	const [showNavBar, setShowNavBar] = useState<boolean>(false);
	const { width } = useWindowDimensions();

	useEffect(() => {
		const md = 768;
		
		// reset when width is over md size
		if (width >= md) {
			setShowNavBar(false);
		} 
	}, [width, showNavBar]);

	function windowDidScroll() {
		// eslint-disable-next-line
		if (navBarContentRef.current != undefined && navBarRef.current != undefined) {

			const scrolledDown = document.body.scrollTop > 18 || document.documentElement.scrollTop > 18;

			// user has SCROLLED DOWN from default level
			if (scrolledDown) {
				navBarRef.current.classList.add("nav-scroll-down");

				navBarContentRef.current.classList.add("nav-scroll-down");
				navBarContentRef.current.classList.remove("backdrop-filter-none");
			}
			else {
				navBarRef.current.classList.remove("nav-scroll-down");

				navBarContentRef.current.classList.remove("nav-scroll-down");
				navBarContentRef.current.classList.add("backdrop-filter-none");
			}
		}
	}
	
	useEffect(() => {
		windowDidScroll();
		window.addEventListener("scroll", windowDidScroll);
		return () => {
			window.removeEventListener("scroll", windowDidScroll);
		}
	},[]);

	function linkClicked() {
		setTimeout(() => {
			setShowNavBar(false);
		}, 100);
	}
	
	return (
		<>
			<nav className="sticky top-0 z-50 h-0" ref={navBarRef}>
				<div className="max-w-7xl mx-auto">
					<div className="px-4 pt-1 pb-1 backdrop-blur-lg backdrop-saturate-150 backdrop-brightness-100 backdrop-filter-none" id="nav-content" ref={navBarContentRef}>
						<div className="flex items-center justify-between flex-wrap">
							<span className="flex flex-row-reverse md:flex-row items-center justify-between h-16 w-full md:w-auto">
								<Link to="/" onClick={linkClicked}>
									<div className="flex flex-row items-center mr-2 md:mr-0">
										<img src={birdLogo} alt="icon" style={{height: "28px"}} className="mr-1"/>
										<span className="text-2xl text-gray-900 font-semibold sans-font bar-title-font whitespace-nowrap">Vincent Neo</span>
									</div>
								</Link>
								<div className="md:hidden">
									<button className="mr-1 ml-3 md:ml-0" onClick={() => {
										setShowNavBar((prev) => !prev)
										}}>
											<FontAwesomeIcon icon={ showNavBar ? faXmark : faBars} fontSize="17pt"/>
									</button>
								</div>
							</span>
							
							<div className={"flex w-full md:w-auto flex-col md:flex-row space-x space-x-2 md:space-x-8 md:mr-3 small-width-bar md:max-h-fit sans-font " + (!showNavBar ? "max-h-0 opacity-0 invisible md:visible md:opacity-100" : "max-h-96 opacity-100") }>
								<Link to="apps" onClick={linkClicked}>
									Apps
								</Link>
								<Link to="design" onClick={linkClicked}>
									Design
								</Link>
								<Link to="blog" onClick={linkClicked}>
									Blog
								</Link>
							</div>


						</div>
					</div>
				</div>
			</nav>
		</>
	);
}

export default Bar;
