
import "../../GlobalFonts.css";
import Footer from "../../Footer";
import { useEffect } from "react";


import sunlightAppIcon from "../../../resources/appicons/Sunlight.png";
import atmosphereAppIcon from "../../../resources/appicons/Atmosphere.png";
import environmentAppIcon from "../../../resources/appicons/Environment.png";
import iconicAppIcon from "../../../resources/appicons/Iconic.png";
import colourConvertAppIcon from "../../../resources/appicons/ColourConvert.png";
import losslessSwitcherAppIcon from "../../../resources/appicons/LosslessSwitcher.png";
import avenueAppIcon from "../../../resources/appicons/Avenue.png";
import ogpxtrackerIcon from "../../../resources/appicons/GPXTracker.png";
import coreGPXIcon from "../../../resources/appicons/CoreGPX2.png";
import uiDeviceCompleteIcon from "../../../resources/appicons/UIDeviceComplete.png";
import trainIslandIcon from "../../../resources/appicons/Train Island.png";
import pigeonIcon from "../../../resources/appicons/Pigeon.png";
import oxpeckerIcon from "../../../resources/appicons/Oxpecker.png";

import style from "./Home.module.css";
import ProductCell from "./ProductCell";
import Card from "./Card";

export type AppsHomeProps = {
    onBackgroundChange: (newColor: string) => void
}

function AppsHome(props: AppsHomeProps): JSX.Element {
    const backgroundColor = "#DADDEB";
    useEffect(() => {
        document.title = "Vincent's Apps";
        props.onBackgroundChange(backgroundColor)
    }, [props]);

    return (
        <>
        <div className="max-w-6xl mx-auto sans-font pt-3 mt-5 pl-6 pr-6 xl:pl-0 xl:pr-0">
            <div className={[style.title, "serif-font", "text-left", "mb-3"].join(" ")}>Apps</div>
            <Card title="For iOS and iPadOS devices">
                    <ProductCell link={"https://apps.apple.com/app/apple-store/id1532005455?pt=121799545&ct=vincent-neo.com%20link&mt=8"} imgSrc={sunlightAppIcon} name="Sunlight" />
                    <ProductCell link={"https://apple.co/3qAPDmH"} imgSrc={atmosphereAppIcon} name="Atmosphere" />
            </Card>

            <Card title="For iOS devices">
                    <ProductCell link={"https://apps.apple.com/sg/app/id6443865952?ct=https://www.vincent-neo.com"} imgSrc={trainIslandIcon} name="Train Island" />
            </Card>

            <Card title="For iOS, watchOS and tvOS devices">
                    <ProductCell link={"https://apps.apple.com/sg/app/environment/id1569224845?itsct=apps_box_link&itscg=30200"} imgSrc={environmentAppIcon} name="Environment" />
            </Card>

            <Card title="For watchOS devices">
                    <ProductCell link={"https://pigeonwatch.app"} imgSrc={pigeonIcon} name="Pigeon" />
                    <ProductCell link={"https://oxpecker.social"} imgSrc={oxpeckerIcon} name="Oxpecker" />
            </Card>

            <Card title="For macOS devices">
                    <ProductCell link={"https://apps.apple.com/sg/app/iconic/id1582751983?mt=12"} imgSrc={iconicAppIcon} name="Iconic" />
                    <ProductCell link={"https://github.com/vincentneo/LosslessSwitcher"} imgSrc={losslessSwitcherAppIcon} name="LosslessSwitcher" subtitle="Open Source" />
                    <ProductCell link={"https://github.com/vincentneo/Avenue-GPX-Viewer"} imgSrc={avenueAppIcon} name="Avenue" subtitle="Open Source" />
                    <ProductCell link={"https://apps.apple.com/sg/app/colourconvert/id1621552385?mt=12"} imgSrc={colourConvertAppIcon} name="ColourConvert" />
            </Card>

            <Card title="Open Source Collaborations">
                    <ProductCell link="https://github.com/merlos/iOS-Open-GPX-Tracker" imgSrc={ogpxtrackerIcon} name="GPX Tracker" subtitle="with @merlos" />
            </Card>

            <div className={[style.title, "serif-font", "text-left", "", "mb-3", "mt-24"].join(" ")}>Libraries</div>

            <Card title="Open Source">
                <ProductCell link="https://github.com/vincentneo/CoreGPX" imgSrc={coreGPXIcon} name="CoreGPX"/>
                <ProductCell link="https://github.com/Nirma/UIDeviceComplete" imgSrc={uiDeviceCompleteIcon} name="UIDeviceComplete" subtitle="with @Nirma"/>
            </Card>
                        

        </div>

        {/* <div className="max-w-6xl mx-auto sans-font pt-3">

                

        </div> */}
        {/* <div style={{background:`linear-gradient(180deg, ${backgroundColor} 8%, #7d6096 100%)`}} className="w-full">

        </div> */}
        <Footer backgroundColor={backgroundColor} foregroundColor="black" shouldGradient={false} />
        </>
    )
}

export default AppsHome;