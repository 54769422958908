import BlogMetadataInterface from "./BlogMetadataInterface";
import {DateTime} from "luxon";
import style from "./BlogArticleCell.module.css";

export type Props = {
    metadata: BlogMetadataInterface;
}

function BlogArticleCell(props: Props): JSX.Element {
    return (
        <>
        <div className={"flex flex-1 pl-5 pr-5 " + style.eachCell}>
            <div className={style.imgContainer}>
                <img src={props.metadata.headerImage} />
            </div>
            <div className={style.sideContent}>
                <h1 className={style.sideTitle}>{props.metadata.title}</h1>
                <h3 className={style.sideSubtitle}>{props.metadata.subtitle}</h3>
                <p className={style.date}>{DateTime.fromFormat(props.metadata.date, "yyyy-LL-dd HH:mm:ss ZZZ").toFormat("d LLLL y")}</p>
            </div>
        </div>
        </>
    )
}

export default BlogArticleCell;
