import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import style from "./Error404.module.css";

export type Error404Props = {
    onBackgroundChange: (newColor: string) => void
}

function Error404(props: Error404Props): JSX.Element {
    const backgroundColor = "#ffebf8";


    useEffect(() => {
        document.title = "Error 404 :(";
        
        const colors = ["#ffeada", "#ececff", "#fbffe0"];

        props.onBackgroundChange(backgroundColor);
        const timer = setInterval(() => {
            props.onBackgroundChange(colors[Math.floor((Math.random()*colors.length))]);
            console.log(backgroundColor)
        }, 3000)
        return () => {
            clearInterval(timer)
        }
    }, [props]);

    return (
        <div className="sans-font my-auto pb-16">
            <p className={style.titleEmoji}>☹️</p>
            <h1 className={style.title}>This page doesn't exist...</h1>
            <p className={style.subtitle}>
                Would you like to return to the home page instead?
            </p>
            <div className={style.homeButtonContainer}>
                <Link to="/" className={style.homeButton}>
                    <FontAwesomeIcon icon={faChevronLeft}/> Home
                </Link>
            </div>
        </div>
    )
}

export default Error404;