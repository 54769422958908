import { useEffect } from "react";
import Footer from "../../Footer";
import style from "./BlogHome.module.css";
import BlogArticleCell from "./BlogArticleCell";
import { Link } from "react-router-dom";
import BlogContents from "./BlogContents";

export type BlogHomeProps = {
    onBackgroundChange: (newColor: string) => void
}

function BlogHome(props: BlogHomeProps): JSX.Element {

    const backgroundColor = "#eeeeee";
    useEffect(() => {
        document.title = "Vincent Neo's Blog"
        props.onBackgroundChange(backgroundColor)
    }, [props])

    return (
        <>
        <div className="max-w-6xl mx-auto w-full">
            <div className={style.mainHeader + " serif-font"}>
                <h1>Articles</h1>
            </div>
        </div>
        <div className="w-full flex justify-center mt-3 pt-8 pb-8" style={{background: "linear-gradient(0deg, rgba(243,243,243,0) 0%, rgba(250,250,250,1) 25%, rgba(250,250,250,1) 85%, rgba(243,243,243,0) 100%)"}}>
            <div className="max-w-3xl mx-auto">
                {BlogContents.getInstance().contents.map((pair) => {
                    return (
                    <Link to={pair.pagePath} key={pair.pagePath}>
                        <BlogArticleCell metadata={pair.metadata}/>
                    </Link>)
                })}
            </div>
        </div>
        <Footer backgroundColor={backgroundColor} foregroundColor="black" shouldGradient={false}/>
        </>
    )
}

export default BlogHome;