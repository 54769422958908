import React, { useCallback, useEffect, useRef, useState } from 'react';
import Bar from './components/Bar';
import './App.css';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import AppsHome from './components/category/apps/AppsHome';
import Error404 from './components/category/error/Error404';
import DesignHome from './components/category/design/DesignHome';
import BlogHome from './components/category/blog/BlogHome';
import BlogContents from './components/category/blog/BlogContents';
import BlogPageContainer from './components/category/blog/BlogPageContainer';
import Privacy from './components/category/privacy/Privacy';

function App() {
  const ref = useRef<HTMLDivElement>(null);
  const [backgroundColor, setBackgroundColor] = useState<string>("white");

  const handleBackgroundColorChange = useCallback((newValue: string) => {
    setBackgroundColor(newValue);
  }, []);

  useEffect(() => {
    document.documentElement.style.backgroundColor = backgroundColor;
    document.head.querySelector("meta[name=theme-color]")?.setAttribute("content", backgroundColor)
  }, [backgroundColor]);

  return (
      <BrowserRouter>
        <div className="App flex flex-col min-h-screen" ref={ref} style={{minHeight: "100vh"}}>
          <Bar/>
          <div className='h-16'></div>
          <Routes>
            <Route path="/" element={<Home onBackgroundChange={handleBackgroundColorChange}/>} />
            <Route path="/apps" element={<AppsHome onBackgroundChange={handleBackgroundColorChange}/>} />
            <Route path="/design" element={<DesignHome onBackgroundChange={handleBackgroundColorChange}/>} />
            <Route path="/posts" element={<Navigate to="/blog"></Navigate>} />
            <Route path="/blog" element={<BlogHome onBackgroundChange={handleBackgroundColorChange}/>} />

            <Route path="/privacy" element={<Privacy />} />

            {
              BlogContents.getInstance().contents.map((pair) => {
                  return [<Route path={"/blog/" + pair.pagePath} key={pair.pagePath} element={
                    <BlogPageContainer onBackgroundChange={handleBackgroundColorChange} metadata={pair.metadata}>
                      {pair.content({})}
                    </BlogPageContainer>
                  } />,
                  <Route path={pair.metadata.v1Path} key={pair.pagePath + "-legacyv1"} element= {
                    <Navigate to={"/blog/" + pair.pagePath} />
                  } />,                   
                  <Route path={pair.metadata.v1Path + ".html"} key={pair.pagePath + "-legacyv1"} element= {
                    <Navigate to={"/blog/" + pair.pagePath} />
                  } />]
              })
            }

            <Route path="/*" element={<Error404 onBackgroundChange={handleBackgroundColorChange}/>} />
          </Routes>
        </div>
      </BrowserRouter>
  );
}

export default App;
