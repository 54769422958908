import { MouseEventHandler, useEffect, useState } from "react";
//import { CSSTransition, SwitchTransition } from "react-transition-group";

//import useWindowDimensions from "../hooks/useWindowDimensions";
import HomeFeature from "../structures/HomeFeature";

import "./GlobalFonts.css";
import "./HomeContent.css";
import HomeFeaturedItems from "../static_data/HomeFeaturedItems";
import { getBadgeURL } from "../structures/AppStoreType";
//import { ColorPreset } from "../structures/ColorPreset";
import TextTransition, {presets} from "react-text-transition";
import TinyCrossfade from "react-tiny-crossfade";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import HomeContentImage from "./HomeContentImage";


//const lg = 1024;
//const md = 768;
//const sm = 640;

export type HomeContentProps = {
    onBackgroundChange: (newColor: string) => void
}

let intervalTimer: NodeJS.Timer

function HomeContent(props: HomeContentProps) {

    const items = HomeFeaturedItems.prepareSelectRandom();
    const firstItem = items.picked;
    const allItems = items.all;
    //const imageContainer = useRef<HTMLImageElement>(null);
    //const { width } = useWindowDimensions();

    //const [isMobile, setMobile] = useState<Boolean>(false);
    const [currentFeature, setCurrentFeature] = useState<HomeFeature>(firstItem);

    const backgroundColor = "rgb(214 243 251)";

    useEffect(() => {
        props.onBackgroundChange(backgroundColor)
    }, [props]);

    // useEffect(() => {
    //     //setMobile(width < md)
    //     //console.log(isMobile)
    // }, [width]);

    useEffect(() => {
        //props.onBackgroundChange(currentFeature.backgroundColor);
        intervalTimer = createInterval()
        return () => {
            clearInterval(intervalTimer);
        }
    }, []);

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    function createInterval(): NodeJS.Timer {
        const featureChangeInterval = 8;
        const featureChangeTimer = setInterval(() => {
            changeFeatured()
        }, featureChangeInterval * 1000);

        return featureChangeTimer
    }

    // useEffect(() => {
    //     console.log("feature changed")
    //     console.log(currentFeature);
    //     //props.onBackgroundChange(currentFeature.backgroundColor);
    // }, [currentFeature])

    function changeFeatured() {
        //const current = currentFeature;
        //const next = current.next!;
        setCurrentFeature(current => current.next!);
        //console.log(currentFeature.name, currentFeature.next!.name, currentFeature.next!.next!.name)
    }

    function renderFeaturedBadges(feature: HomeFeature): JSX.Element[] {
        return feature.badges.map((badge) => {
                    const badgeImg = getBadgeURL(badge.type);
                    return (
                        <a href={badge.link} key={badge.link + badge.type}>
                            <img src={badgeImg} alt="app store download link" className="download-img"/>
                        </a>)
                })
    }

    function renderFeaturedLinks(feature: HomeFeature): JSX.Element[] {
        return feature.links.map((each) => {
                    return (
                    <a href={each.link} className="featured-clickable-links align-middle" key={each.title}>
                        <span>
                            <span className="sans-font">{each.title}</span>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </span>
                    </a>)
               })
    }

    function renderItemSelectionBar(item: HomeFeature): JSX.Element {
        return (
            <div className="flex flex-row md:justify-start justify-center item-bar">
            {
                allItems.map(renderItem => {
                    const isSelected = renderItem.productTitle == item.productTitle
                    const className = `bar-item ${isSelected ? "selected-bar-item" : "unselected-bar-item"}`
                    return (
                    <a key={renderItem.miniIconUrl} href="#" className={`crossfadableItems ${className}`} onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        setCurrentFeature(renderItem)
                        clearInterval(intervalTimer)
                        intervalTimer = createInterval()
                       }}>
                        <img alt={`Select ${renderItem.productTitle}`} src={renderItem.miniIconUrl}/>
                    </a>
                    )
                })
            }
            </div>
        )
    }


    return (
    <div className="home-content">
        {
            isMobile ? (
            <div className="max-w-6xl md:mx-auto md:w-full">
                {renderItemSelectionBar(currentFeature)}
            </div>) : <></>
        }
        <div className="max-w-6xl md:mx-auto home-content-transition home-min-h md:w-full">
            <div className="grid grid-cols-2 content-center flex-1">
                <div className="flex-col left-contents col-span-2 md:col-span-1 md:w-full">
                    <div className="title serif-font">
                        <div className="title-lg">{currentFeature.largeTitle}</div>
                        <div className="title-md">
                        <TextTransition springConfig={presets.wobbly} inline={true} className="ml-auto mr-auto">
                            {currentFeature.smallTitle}                        
                        </TextTransition>
                        </div>
                    </div>
                    <div className="">
                        <TinyCrossfade className="crossfadableProductDescription" duration={300} disableInitialAnimation={true}>
                            <div key={currentFeature.productTitle}>
                                <div className="subtitle sans-font">{currentFeature.productTitle}</div>
                                <div className="md:hidden max-w-md mx-auto mt-5">
                                    {/* {getImageDiv()} */}
                                    <HomeContentImage currentFeature={currentFeature} />
                                </div>
                                <div className="description sans-font md:w-full">
                                    {currentFeature.description}
                                </div>
                                <div className="flex flex-row flex-wrap badges-container space-x-2">
                                    {renderFeaturedBadges(currentFeature)}
                                <div className="flex flex-row flex-wrap space-x-3">{renderFeaturedLinks(currentFeature)}</div>
                                </div>
                            </div>
                        </TinyCrossfade>
                    </div>
                </div>
                <div className="invisible md:visible flex-col content-middle">
                    <HomeContentImage currentFeature={currentFeature} />
                </div>
            </div>
        </div>
        {
            !isMobile ? (
            <div className="max-w-6xl md:mx-auto md:w-full">
                {renderItemSelectionBar(currentFeature)}
            </div>) : <></>
        }
    </div>
    )
}

export default HomeContent;