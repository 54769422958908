import sketchLogo from "../resources/external/sketch.svg";
import swiftLogo from "../resources/external/swift.svg";
import "./Sections.css";
import styles from "./Achievements.module.css";

type AchievementsCellProps = {
    imageURL: string,
    title: string,
    subtitle: string
}

function AchievementsCell(props: AchievementsCellProps): JSX.Element {
    return (
        <div className="flex flex-row items-center">
            <img src={props.imageURL} alt="Logo representing Swift Language" className={styles.icon}/>
            <div className="flex flex-col items-start ml-3 text-left">
                <div className={["sans-font", styles.title].join(" ")}>{props.title}</div>
                <div className={["sans-font", styles.subtitle].join(" ")}>{props.subtitle}</div>
            </div>
        </div>
    )
}

function Achievements(): JSX.Element {
    return (
    <>
        <div className={["pt-5", "pb-6", styles.root, styles.fadeInBg].join(" ")}>
            <div className="max-w-6xl md:mx-auto">
                <div className="flex flex-col items-start ml-5">
                    <div className="section-title serif-font">Achievements</div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 pt-4 md:pt-5">
                        <AchievementsCell imageURL={swiftLogo} title="WWDC Swift Student Challenge" subtitle="Winner, 2020 and 2021" />
                        <AchievementsCell imageURL={sketchLogo} title="Sketch Glassmorphism Challenge" subtitle="3rd Place, 2022" />
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Achievements;
