import iosBadgeBlack from "../resources/app-store-black.svg";
import atvBadgeBlack from "../resources/app-store-atv-black.svg";
import macBadgeBlack from "../resources/app-store-mac-black.svg";
// import iosBadgeWhite from "../resources/app-store-white.svg";
// import atvBadgeWhite from "../resources/app-store-atv-white.svg";
// import macBadgeWhite from "../resources/app-store-mac-white.svg";

export const enum AppStoreType {
    iOS,
    tvOS,
    macOS
}

export function getBadgeURL(type: AppStoreType): string {
    switch (type) {
        case AppStoreType.iOS: return iosBadgeBlack;
        case AppStoreType.macOS: return macBadgeBlack;
        case AppStoreType.tvOS: return atvBadgeBlack;
    }
}