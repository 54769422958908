import AppStoreLink from "./AppStoreLink";
import { ColorPreset } from "./ColorPreset";
import HomeFeatureLink from "./HomeFeatureLink";

export default class HomeFeature {
    largeTitle: string;
    smallTitle: string;
    
    productTitle: string;
    description: string;
    
    imageUrl: string;
    miniIconUrl: string;

   //backgroundColor: ColorPreset;
    badges: AppStoreLink[];
    links: HomeFeatureLink[];

    next?: HomeFeature; 
    
    constructor(largeTitle: string, 
                smallTitle: string, 
                productTitle: string, 
                description: string, 
                imageUrl: string,
                miniIconUrl: string,
                badges: AppStoreLink[], links: HomeFeatureLink[]) {
        this.largeTitle = largeTitle
        this.smallTitle = smallTitle
        this.productTitle = productTitle
        this.description = description
        this.imageUrl = imageUrl
        this.miniIconUrl = miniIconUrl
        //this.backgroundColor = backgroundColor
        this.badges = badges
        this.links = links
    }

}
    
    