
export default class HomeFeatureLink {
    title: string;
    link: string;

    constructor(title: string, link: string) {
        this.title = title
        this.link = link
    }
}
