import { AppStoreType } from "./AppStoreType";

export default class AppStoreLink {
    type: AppStoreType;
    link: string;

    constructor(type: AppStoreType, link: string) {
        this.type = type;
        this.link = link;
    }
}