
import style from "./Card.module.css";

export type CardProps = {
    children: JSX.Element[] | JSX.Element;
    title: string;
}

function Card(props: CardProps): JSX.Element {
    return (
            <div className={style.sectionCard + ""}>
                <div className={style.title + " sans-font text-xl md:text-2xl"}>{props.title}</div>
                <div className="grid grid-cols-3 md:grid-cols-5 lg:grid-cols-7 gap-8 md:gap-5">
                    {props.children}
                </div>
            </div>
        
    )
}

export default Card;