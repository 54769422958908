import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";
import style from "./ProductCell.module.css";

export type ProductCellProps = {
    link: string;
    imgSrc?: string;
    faSrc?: IconProp;
    name: string;
    subtitle?: string;
    fixedSize?: boolean;
}

function ProductCell(props: ProductCellProps) {

    function imgStyle(type: string): CSSProperties {
        return props.fixedSize ?? false ? {height: type === "fa" ? "70px" : "90px", margin: "20px"} : {}
    }
    return (
        <>
            <a href={props.link}>
                <div className="flex flex-col items-center justify-center text-center font-semibold tight-font">
                    {props.imgSrc !== undefined ?
                    <img src={props.imgSrc} alt="App Icon" style={imgStyle("")}/> :
                    <FontAwesomeIcon style={imgStyle("fa")} icon={props.faSrc!} />
                    }
                    <span className={[style.cellLabel, "text-sm", "md:text-md", "xl:text-lg", "opacity-75"].join(" ")}>{props.name}</span>
                    {props.subtitle !== undefined ? 
                        <span className={[style.cellLabel, "text-sm", "md:text-md", "xl:text-lg", "opacity-50"].join(" ")}>{props.subtitle}</span> : ""}
                </div>
            </a>
        </>
    )
}

export default ProductCell;