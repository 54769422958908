import { PressCellProps } from "./PressCellProps";

function PressCell(props: PressCellProps): JSX.Element {

    const imgClasses = "max-h-16 md:max-h-12";

    return (
        <>
        <div className="mt-auto mb-auto ml-auto mr-auto">
            <div>
            { props.pageLink !== undefined ? 
                <a title={props.description} href={props.pageLink}>
                    <img src={props.imageURL} alt={props.altText} className={imgClasses}/>
                </a> : 
                <img src={props.imageURL} alt={props.altText} title={props.description} className={imgClasses}/>
            }
            </div>
        </div>
        </>
    )
}

export default PressCell;