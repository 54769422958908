import HomeFeature from "../structures/HomeFeature";

import sunlightImageURL from "../resources/Sunlight-trim2.png";
import environmentImageURL from "../resources/Environment2.png";
import atmosphereImageURL from "../resources/Atmosphere2.png";
import iconicImageURL from "../resources/Iconic2.png";
import colorConvertImageURL from "../resources/ColorConvert2.png";
import llsImageURL from "../resources/LosslessSwitcher.png";
import avenueImageURL from "../resources/Avenue2.png";
import trainIslandImageURL from "../resources/TrainIsland.png";
import pigeonImageURL from "../resources/Pigeon.png";
import oxpeckerImageURL from "../resources/Oxpecker.png";

import sunlightMiniIconURL from "../resources/appicons/Sunlight.png";
import environmentMiniIconURL from "../resources/appicons/Environment.png";
import atmosphereMiniIconURL from "../resources/appicons/Atmosphere.png";
import iconicMiniIconURL from "../resources/appicons/Iconic.png";
import colorConvertMiniIconURL from "../resources/appicons/ColourConvert.png";
import llsMiniIconURL from "../resources/appicons/LosslessSwitcher.png";
import avenueMiniIconURL from "../resources/appicons/Avenue.png";
import trainIslandMiniIconURL from "../resources/appicons/Train Island.png";
import pigeonMiniIconURL from "../resources/appicons/Pigeon.png";
import oxpeckerMiniIconURL from "../resources/appicons/Oxpecker.png";


import { AppStoreType } from "../structures/AppStoreType";
//import { ColorPreset } from "../structures/ColorPreset";
import AppStoreLink from "../structures/AppStoreLink";
import HomeFeatureLink from "../structures/HomeFeatureLink";

export default class HomeFeaturedItems {

    static sunlight(): HomeFeature {
        const largeTitle = "I make";
        const smallTitle = "iOS, iPadOS apps like:";
        const productTitle = "Sunlight";
        const description = 
        `   Sun and moon tracking, with beautiful widgets and visuals.
            Live calculations, computed locally on device.
            Anywhere, anytime.
        `;
        const storeLink = "https://apps.apple.com/app/apple-store/id1532005455?pt=121799545&ct=vincent-neo.com%20link&mt=8";
        const imageURL = sunlightImageURL;
        const miniIconURL = sunlightMiniIconURL;
        //const background = ColorPreset.sunlightBackground;
        const badges = [
            new AppStoreLink(AppStoreType.iOS, storeLink)
        ];

        return new HomeFeature(largeTitle, smallTitle, productTitle, description, imageURL, miniIconURL, badges, []);
    }

    static environment(): HomeFeature {
        const largeTitle = "I make";
        const smallTitle = "iOS, tvOS, watchOS apps like:";
        const productTitle = "Environment";
        const description = 
        `   Built on weather data from local sources.
            Fast, attractive and accurate weather app.
            Works on your phone, watch and TV.
            4.7 stars in Singapore App Store ratings.
        `;
        const storeLink = "https://apps.apple.com/sg/app/environment/id1569224845?itsct=apps_box_link&itscg=30200";
        const imageURL = environmentImageURL;
        const miniIconURL = environmentMiniIconURL;
        //const background = ColorPreset.sunlightBackground;
        const badges = [
            new AppStoreLink(AppStoreType.iOS, storeLink),
            new AppStoreLink(AppStoreType.tvOS, storeLink)
        ];

        return new HomeFeature(largeTitle, smallTitle, productTitle, description, imageURL, miniIconURL, badges, []);
    }

    static atmosphere(): HomeFeature {
        const largeTitle = "I make";
        const smallTitle = "iOS, iPadOS apps like:";
        const productTitle = "Atmosphere";
        const description = 
        `   Simple and smart, AI powered cloud detection.
            Take pictures and classify, all in one app.
            AI runs on device. No internet connection needed.
        `;
        const storeLink = "https://apple.co/3qAPDmH";
        const imageURL = atmosphereImageURL;
        const miniIconURL = atmosphereMiniIconURL;
        //const background = ColorPreset.sunlightBackground;
        const badges = [
            new AppStoreLink(AppStoreType.iOS, storeLink)
        ];

        return new HomeFeature(largeTitle, smallTitle, productTitle, description, imageURL, miniIconURL, badges, []);
    }

    static losslessSwitcher(): HomeFeature {
        const largeTitle = "I make";
        const smallTitle = "Open Source Projects like:";
        const productTitle = "LosslessSwitcher";
        const description = 
        `   Automatically switch device sample rate.
            Built for Mac users of Apple Music Lossless.
            100% Open Source.
        `;
        const imageURL = llsImageURL;
        const miniIconURL = llsMiniIconURL;

        const downloadLink = new HomeFeatureLink("Download", "https://github.com/vincentneo/LosslessSwitcher/releases");

        const sourceCodeLink = new HomeFeatureLink("View Source", "https://github.com/vincentneo/LosslessSwitcher");
        const links = [downloadLink, sourceCodeLink];

        return new HomeFeature(largeTitle, smallTitle, productTitle, description, imageURL, miniIconURL, [], links);
    }

    static avenue(): HomeFeature {
        const largeTitle = "I make";
        const smallTitle = "macOS apps like:";
        const productTitle = "Avenue";
        const description = 
        `   Easy and simple GPX viewing experience on the Mac.
            Quick Look enabled, integrates with Finder.
            100% Open Source.
        `;
        const storeLink = "https://apps.apple.com/app/apple-store/id1523681067?pt=121799545&ct=WebsiteLink&mt=8";
        const imageURL = avenueImageURL;
        const miniIconURL = avenueMiniIconURL;

        //const background = ColorPreset.sunlightBackground;
        const badges = [
            new AppStoreLink(AppStoreType.macOS, storeLink)
        ];

        const sourceCodeLink = new HomeFeatureLink("View Source", "https://github.com/vincentneo/Avenue-GPX-Viewer");
        const links = [sourceCodeLink];

        return new HomeFeature(largeTitle, smallTitle, productTitle, description, imageURL, miniIconURL, badges, links);
    }


    static iconic(): HomeFeature {
        const largeTitle = "I make";
        const smallTitle = "macOS apps like:";
        const productTitle = "Iconic";
        const description = 
        `   Make your Mac folders less boring.
            Use Emojis, SF Symbols or your images.
            Gel everything up with blend modes.
        `;
        const storeLink = "https://apps.apple.com/sg/app/iconic/id1582751983?mt=12";
        const imageURL = iconicImageURL;
        const miniIconURL = iconicMiniIconURL;
        //const background = ColorPreset.sunlightBackground;
        const badges = [
            new AppStoreLink(AppStoreType.macOS, storeLink) 
        ];

        return new HomeFeature(largeTitle, smallTitle, productTitle, description, imageURL, miniIconURL, badges, []);
    }

    static colourConvert(): HomeFeature {
        const largeTitle = "I make";
        const smallTitle = "macOS apps like:";
        const productTitle = "ColourConvert";
        const description = 
        `   Turn #colour into:
            anything from UIColor to CGColor,
            to hwb(), rgba(), hsl() and more.
        `;
        const storeLink = "https://apps.apple.com/sg/app/colourconvert/id1621552385?mt=12";
        const imageURL = colorConvertImageURL;
        const miniIconURL = colorConvertMiniIconURL;
        const badges = [
            new AppStoreLink(AppStoreType.macOS, storeLink) 
        ];

        return new HomeFeature(largeTitle, smallTitle, productTitle, description, imageURL, miniIconURL, badges, []);
    }

    static trainIsland(): HomeFeature {
        const largeTitle = "I make";
        const smallTitle = "iOS apps like:";
        const productTitle = "Train Island";
        const description = 
        `   Easy MRT route planning.
            Let Dynamic Island and Live Activities,
            tell you which MRT station you're at.
        `;
        const storeLink = "https://apps.apple.com/sg/app/id6443865952?ct=https://www.vincent-neo.com";
        const imageURL = trainIslandImageURL;
        const miniIconURL = trainIslandMiniIconURL;
        const badges = [
            new AppStoreLink(AppStoreType.iOS, storeLink) 
        ];

        return new HomeFeature(largeTitle, smallTitle, productTitle, description, imageURL, miniIconURL, badges, []);
    }

    static pigeon(): HomeFeature {
        const largeTitle = "I make";
        const smallTitle = "watchOS apps like:";
        const productTitle = "Pigeon";
        const description = 
        `   Native, unofficial Telegram client.
            Runs on the Apple Watch.
            Tiny yet powerful.
        `;
        const storeLink = "https://apps.apple.com/us/app/pigeon-client-for-telegram/id1671939892";
        const imageURL = pigeonImageURL;
        const miniIconURL = pigeonMiniIconURL;

        const badges = [
            new AppStoreLink(AppStoreType.iOS, storeLink) 
        ];

        const websiteLink = new HomeFeatureLink("Learn more", "https://pigeonwatch.app");
        const links = [websiteLink];

        return new HomeFeature(largeTitle, smallTitle, productTitle, description, imageURL, miniIconURL, badges, links);
    }

    static oxpecker(): HomeFeature {
        const largeTitle = "I make"
        const smallTitle = "watchOS apps like:"
        const productTitle = "Oxpecker"
        const description = 
        `   Native, performant Mastodon client.
            Runs on the Apple Watch.
            Tiny but mighty.
        `

        const storeLink = "https://apps.apple.com/us/app/oxpecker-for-mastodon/id6474893905?ct=vincent-neo.com";
        const imageURL = oxpeckerImageURL;
        const miniIconURL = oxpeckerMiniIconURL;

        const badges = [
            new AppStoreLink(AppStoreType.iOS, storeLink) 
        ];

        const websiteLink = new HomeFeatureLink("Learn more", "https://oxpecker.social/features/mastodon-client-for-apple-watch");
        const links = [websiteLink];

        return new HomeFeature(largeTitle, smallTitle, productTitle, description, imageURL, miniIconURL, badges, links);
    }

    static prepareSelectRandom(): {picked: HomeFeature, all: HomeFeature[]} {

        const sunlightFeature = this.sunlight();
        const environmentFeature = this.environment();
        const atmosphereFeature = this.atmosphere();
        const losslessSwitcherFeature = this.losslessSwitcher();
        const avenueFeature = this.avenue();
        const iconicFeature = this.iconic();
        const colorConvertFeature = this.colourConvert();
        const trainIslandFeature = this.trainIsland();
        const pigeonFeature = this.pigeon();
        const oxpeckerFeature = this.oxpecker();

        // LINKED LIST
        sunlightFeature.next = environmentFeature; // first
        environmentFeature.next = atmosphereFeature;
        atmosphereFeature.next = losslessSwitcherFeature;
        losslessSwitcherFeature.next = avenueFeature;
        avenueFeature.next = pigeonFeature;
        pigeonFeature.next = oxpeckerFeature;
        oxpeckerFeature.next = iconicFeature;
        iconicFeature.next =  colorConvertFeature; 
        colorConvertFeature.next = trainIslandFeature; 
        trainIslandFeature.next = sunlightFeature; // last

        // SETUP
        let all: HomeFeature[] = [];
        all.push(sunlightFeature);
        all.push(environmentFeature);
        all.push(atmosphereFeature);
        all.push(losslessSwitcherFeature);
        all.push(avenueFeature);
        all.push(pigeonFeature);
        all.push(oxpeckerFeature);
        all.push(iconicFeature);
        all.push(colorConvertFeature);
        all.push(trainIslandFeature);
        let randomPicked = all[Math.floor(Math.random() * all.length)];

        // console.log(losslessSwitcherFeature);
        return {picked: randomPicked, all: all};
    }
}