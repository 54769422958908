import { useEffect } from "react";
import dedent from "dedent";

import style from "./DesignHome.module.css";
import imgAppIcons from "../../../resources/design/App Icons.png";
import imgAtmosphereIcons from "../../../resources/design/Atmosphere.png";
import imgEnvironmentIcons from "../../../resources/design/Environment.png";

import imgSketch1 from "../../../resources/design/Sketch2022-1.png";
import imgSketch2 from "../../../resources/design/Sketch2022-2.png";
import imgSketch3 from "../../../resources/design/Sketch2022-3.1.png";
import Footer from "../../Footer";

export type DesignHomeProps = {
    onBackgroundChange: (newColor: string) => void
}

class CellData {
    title: string;
    subtitle: string;
    caption?: string;
    description: string;
    imageURL: string;

    constructor(title: string, subtitle: string, description: string, imageURL: string) {
        this.title = title;
        this.subtitle = subtitle;
        this.description = description;
        this.imageURL = imageURL;
    }

    static data(): CellData[] {
        let arr: CellData[] = []

        const appIconDesc = dedent`
            As of writing, all personally published apps have app icons made by myself.

            The first app icon design was done on Adobe Photoshop, which is Sunlight's app icon (far top-left icon), with newer icons created using Figma or Sketch.
        `
        let appIcons = new CellData("Since 2020", "App Icons", appIconDesc, imgAppIcons);
        arr.push(appIcons);

        const sk3Desc = dedent`
        In this challenge, I tried to practice a few more tricks in my designs, such as simulated smoke using blur effects and 2.5D design.

        Most of my submitted works are of modern macOS app icon sizes.

        A wide range of themes were explored, from classic cassette tape players to instant cup noodles to vintage cars and more. These themes were conceptualized as individual app icons.
        `
        let sk3 = new CellData("2022", "App Icon Challenge", sk3Desc, imgSketch3);
        sk3.caption = "Sketch Design Challenge 3"
        arr.push(sk3);

        const sk2Desc = dedent`
            This challenge's theme is glassmorphism, which is basically software rendered designs of frosted glass-like appearances of icons, UI, etc.

            I submitted three pieces of works for this challenge. Through this challenge, I practiced design creation with a lot of simulated lighting, via gradients, as well as the use of various blurring techniques.

            The first work was an exposed hard disk drive, with various metallic parts imagined as frosted glass, while the second work was the exploration of the 😍 emoji, expressed as a glass orb.

            The third work was a modern day controller, if it's front was frosted glass, inspired by the translucent game controller cases of the 90s.
            This piece of work won the third prize of the challenge!
        `
        let sk2 = new CellData("2022", "Glassmorphism Challenge", sk2Desc, imgSketch2);
        sk2.caption = "Sketch Design Challenge 2"
        arr.push(sk2);

        const sk1Desc = dedent`
        This was the first installment of the Sketch Design Challenge, which is organised by Sketch B.V., which creates the design software and platform - Sketch.

        I joined this challenge to practice on skeuomorphism design, as well as to familiarise with using the software, Sketch. 
        
        Two pieces of works were submitted. The first was a potted plant, second, was a washing machine imagined as an app icon.
        `
        let sk1 = new CellData("2022", "Sketchmorphism Challenge", sk1Desc, imgSketch1);
        sk1.caption = "Sketch Design Challenge 1"
        arr.push(sk1);

        const envDesc = dedent`
        With a total of 23 specially designed variants, each of the icons designed in Figma, represents a weather condition that could be experienced in Singapore.

        This set of icons are shown as the face of the app, and compliments the entire user experience.
        `
        let env = new CellData("2021", "Environment App Resources", envDesc, imgEnvironmentIcons);
        arr.push(env);

        const atmosphereDesc = dedent`
            Designed using Figma, these icons showcase the unique traits of the 10 main cloud formations supported by Atmosphere's cloud detection AI feature.

            Each icon is crafted with references of how each formation would typically look like, such that each icon would both fit the look and feel of the app, as well as the traits of the cloud formation.
        `
        let atmosphere = new CellData("2021", "Atmosphere App Resources", atmosphereDesc, imgAtmosphereIcons);
        arr.push(atmosphere);

        return arr;
    }
}

function DesignHome(props: DesignHomeProps): JSX.Element {

    const backgroundColor = "#E2D3CB";

    useEffect(() => {
        document.title = "Vincent Neo | Design"
        props.onBackgroundChange(backgroundColor)
    }, [props]);

    function leftCell(title: string, subtitle: string, description: string, caption?: string): JSX.Element {
        return (
            <>
                <div className={`${style.leftCell} sans-font`}>
                    <div className={style.leftCellTitle}>{title}</div>
                    <div className={style.leftCellSubtitle}>{subtitle}</div>
                    {caption !== undefined ? 
                    (<div className={style.leftCellCaption}>
                        {caption}
                    </div>) : ""}
                    <div className={`${style.leftCellDescription}`}>
                        {description}
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
        <div className="max-w-6xl mx-auto w-full">
            <div className="title">
                <div className={style.mainHeader + " serif-font"}>
                    <h1>Design.</h1>
                    <p>Here are some of my design-related works, throughout the years.</p>
                </div>
                <div className={style.description}>
                    {CellData.data().map((data) => {
                        return (
                            <div className={`grid md:grid-cols-3 lg:grid-cols-7 gap-1 ${style.dataContainer}`}>
                                    <div className="lg:col-span-3">
                                        <div className={style.yearTitle}>
                                            {leftCell(data.title, data.subtitle, data.description, data.caption)}
                                        </div>
                                    </div>
                                    <div className="md:col-span-2 lg:col-span-4">
                                        <img src={data.imageURL} className={style.rightCell} alt={data.description}/>
                                    </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Footer backgroundColor={backgroundColor} foregroundColor={"black"} shouldGradient={false} />
        </div>
        </>
    )
}

export default DesignHome;
